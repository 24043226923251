import React from "react"

import Header from "./header"
import Footer from "./footer"

import "../styles/index.css"

const Layout = ({ location, pageTitle, children }) => (
  <div className="antialiased flex flex-col w-full h-screen">
    <Header />
    <main className="flex-1 relative z-0 md:mt-20">{children}</main>
    <Footer location={location} pageTitle={pageTitle} />
  </div>
)

export default Layout
