import React from "react"
import { HamburgerSpin } from "react-animated-burgers"

const NavButton = ({ handleToggleNav, navOpen }) => (
  <div className="md:hidden bg-brand hover:bg-brand-lighter rounded-sm">
    <HamburgerSpin
      toggleButton={handleToggleNav}
      isActive={navOpen}
      buttonWidth={30}
      barColor="white"
      style={{ paddingBottom: "8px", paddingTop: "14px" }}
    />
  </div>
)

export default NavButton
