import React from "react"
import { Link } from "gatsby"
import FooterEmailSubscribe from "./footer/footerEmailSubscribe"

const Footer = ({ location, pageTitle }) => {
  return (
    <footer className="bg-brand-dark mt-10">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Reviews
                </div>
                <ul className="mt-4">
                  <li>
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Latest
                    </span>
                  </li>
                  <li className="mt-4">
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Top Rated
                    </span>
                  </li>
                  <li className="mt-4">
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Deals
                    </span>
                  </li>
                  <li className="mt-4">
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Ideas
                    </span>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Content
                </div>
                <ul className="mt-4">
                  <li>
                    <Link
                      to="/category/gamer-health/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Gamer Health
                    </Link>
                  </li>
                  <li className="mt-4">
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Products & Tech
                    </span>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/category/top-titles/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Top Titles
                    </Link>
                  </li>
                  <li className="mt-4">
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Industry News
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Company
                </div>
                <ul className="mt-4">
                  <li>
                    <span
                      // href="#"
                      className="select-none text-base opacity-50 leading-6 text-gray-300"
                      // className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      About
                    </span>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/articles/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Articles
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/blog/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/contact/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                  Legal
                </div>
                <ul className="mt-4">
                  <li>
                    <Link
                      to="/disclosure/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Disclosure
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/privacy/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Privacy
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link
                      to="/terms/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Terms
                    </Link>
                  </li>
                  <li className="mt-4">
                    <a
                      href="/sitemap.xml/"
                      className="text-base leading-6 text-gray-300 hover:text-white"
                    >
                      Sitemap
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8 xl:mt-0">
            <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
              Subscribe to our newsletter
            </div>
            <p className="mt-4 text-gray-300 text-base leading-6">
              The latest news, articles, and resources, sent to your inbox
              weekly.
            </p>
            <FooterEmailSubscribe location={location} pageTitle={pageTitle} />
            {/* <FooterHubspotForm /> */}
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex md:order-2">
            <a
              href="https://www.facebook.com/stimpackenergy/"
              className="text-gray-400 hover:text-brand-lighter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <div
              // href="#"
              // className="ml-6 text-gray-400 hover:text-brand-lighter"
              // target="_blank"
              // rel="noopener noreferrer"
              className="select-none ml-6 text-gray-400 opacity-50"
              // className="text-base leading-6 text-gray-300 hover:text-white"
            >
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <a
              href="https://twitter.com/stimpackenergy?lang=en"
              className="ml-6 text-gray-400 hover:text-brand-lighter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UCvmjgvazOPgLdhaTUSlx6gQ"
              className="ml-6 text-gray-400 hover:text-brand-lighter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="sr-only">Twitter</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 512 512"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
              >
                <g fillRule="nonzero">
                  <path d="M501.299 132.766c-5.888-22.03-23.234-39.377-45.264-45.264-39.932-10.701-200.037-10.701-200.037-10.701s-160.105 0-200.038 10.701c-22.025 5.887-39.376 23.234-45.264 45.264C0 172.694 0 256.002 0 256.002s0 83.308 10.696 123.232c5.888 22.03 23.239 39.381 45.264 45.268 39.933 10.697 200.038 10.697 200.038 10.697s160.105 0 200.037-10.697c22.03-5.887 39.376-23.238 45.264-45.268C512 339.31 512 256.002 512 256.002s0-83.308-10.701-123.236z" />
                  <path
                    className="text-brand-dark"
                    d="M204.796 332.803l133.018-76.801-133.018-76.801v153.602z"
                  />
                </g>
              </svg>
            </a>
          </div>
          <p className="mt-8 text-base leading-6 text-gray-400 md:mt-0 md:order-1">
            Copyright © 2016-{new Date().getFullYear()} Stimpack™ LLC | All
            Rights Reserved |{" "}
            <span>
              Built by
              {` `}
              <a
                className="text-base hover:text-brand"
                href="https://taylorstauss.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Taylor Stauss
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
