import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import NavLogo from "./header/navLogo"
import NavButton from "./header/navButton"
import NavLinks from "./header/navLinks"

const query = graphql`
  query Header {
    contentfulBrand {
      logo {
        file {
          url
        }
      }
    }
    contentfulAsset(title: { eq: "pattern-black-50" }) {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    contentfulNavigation(title: { eq: "Main Nav" }) {
      id
      title
      navigationElements {
        id
        title
        linkText
        linkSlug
        # childLinks {
        #   id
        #   title
        #   linkText
        #   linkSlug
        # }
      }
    }
  }
`

const Header = () => {
  const [navOpen, setNavOpen] = useState(false)

  const handleToggleNav = () => {
    setNavOpen(!navOpen)
  }

  return (
    <StaticQuery
      query={query}
      render={({
        contentfulBrand: { logo },
        contentfulAsset: { fluid },
        contentfulNavigation: { navigationElements: links },
      }) => {
        return (
          <header className="relative md:fixed md:top-0 md:right-0 md:left-0 z-50 bg-brand-dark">
            <nav role="navigation" aria-label="main-nav">
              <BackgroundImage
                Tag="div"
                fluid={fluid}
                style={{
                  backgroundRepeat: "repeat",
                  backgroundSize: "auto",
                  overflow: "visible",
                }}
                fadeIn={false}
                loading="eager"
              >
                <div className="container mx-auto flex items-center justify-between flex-no-wrap py-4 px-4">
                  <NavLogo logo={logo} />
                  <NavLinks links={links} navMobile={false} />
                  <NavButton
                    navOpen={navOpen}
                    handleToggleNav={handleToggleNav}
                  />
                </div>
              </BackgroundImage>
              {navOpen && <NavLinks links={links} navMobile={true} />}
            </nav>
          </header>
        )
      }}
    />
  )
}

export default Header
