import React, { useState } from "react"
import queryString from "query-string"
import { useForm } from "react-hook-form"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import ErrorIcon from "../errorIcon"

const FooterEmailSubscribe = ({ location, pageTitle }) => {
  const [submitted, setSubmitted] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState()
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
    reset,
  } = useForm({ mode: "onBlur" })
  const onSubmit = async ({ email, botField }, e) => {
    e.preventDefault()
    if (recaptchaToken === undefined || recaptchaToken === "") {
      return setError("recaptcha", "invalid", "Google Recaptcha Error")
    }

    try {
      const response = await fetch(
        `/.netlify/functions/footer-email-subscribe-submitted`,
        {
          method: "POST",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: queryString.stringify({
            "form-name": e.target.getAttribute("name"),
            email,
            botField,
            recaptchaToken,
            hutk:
              document.cookie.replace(
                /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
                "$1"
              ) || "",
            pageUri: location.href,
            pageName: pageTitle,
          }),
        }
      )

      const data = await response.json()

      if (response.status === 422) {
        if (data.errors.recaptchaToken) {
          return setError("recaptcha", "invalid", "Google Recaptcha Error")
        } else if (data.errors.formName) {
          return setError("form-name", "invalid", "There was a form error")
        } else if (data.errors.botField) {
          return setError("botField", "invalid", "You are spam, stay away")
        } else {
          setError()
        }
        window.grecaptcha.reset()
        setSubmitted(false)
      }

      if (response.status === 200) {
        reset()
        setSubmitted(true)
      }
    } catch (err) {
      console.error(`error: ${err}`)
      setError("submit", "submitError", err)
    }
  }

  return (
    <>
      {submitted ? (
        <p className="mt-4 text-gray-300 text-base leading-6">
          Thanks for signing up!
        </p>
      ) : (
        <div className="relative">
          {isSubmitting && (
            <div
              className="absolute flex justify-center items-center z-50"
              style={{
                top: "-10px",
                right: "-10px",
                bottom: "-10px",
                left: "-10px",
              }}
            >
              <div className="absolute shadow inset-0 flex justify-center items-center bg-gray-300 rounded opacity-25" />
              <FontAwesomeIcon
                className="relative text-3xl text-brand"
                icon={faSpinner}
                spin
              />
            </div>
          )}
          <form
            className="my-4 sm:flex sm:max-w-md"
            onSubmit={handleSubmit(onSubmit)}
            name="Footer Email Subscribe"
          >
            <div className="hidden">
              <label htmlFor="footer-subscribe-form-bot-field">
                Don't fill this out:
                <input
                  id="footer-subscribe-form-bot-field"
                  type="text"
                  name="botField"
                  ref={register}
                />
              </label>
            </div>
            <div>
              <div className="relative">
                <label id="emailId" htmlFor="email" className="hidden">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  aria-labelledby="emailId"
                  placeholder="Enter your email"
                  className="w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                  ref={register({
                    required: true,
                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {errors.email && <ErrorIcon />}
              </div>
              {errors.email && (
                <p className="mt-2 text-xs text-red-600">Email is required.</p>
              )}
            </div>

            <GoogleReCaptcha
              onVerify={(token) => {
                setRecaptchaToken(token)
              }}
            />

            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-brand hover:bg-brand-lighter focus:outline-none focus:bg-brand-lighter transition duration-150 ease-in-out"
                onClick={() => setSubmitted(false)}
                disabled={isSubmitting}
              >
                Subscribe
              </button>
            </div>
          </form>
          <small>
            <div className="text-xs text-gray-300 leading-6">
              This form is protected by reCAPTCHA and the Google{" "}
              <a
                className="underline hover:opacity-75"
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className="underline hover:opacity-75"
                href="https://policies.google.com/terms"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              apply.
            </div>
          </small>
        </div>
      )}
    </>
  )
}

export default FooterEmailSubscribe
