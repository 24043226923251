import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import defaultImage from "../images/stimpack-logo-meta-image-min.jpg"

const query = graphql`
  query SEO {
    contentfulBrand {
      defaultTitle: companyName
      defaultDescription: description
      siteUrl: website
      twitterUsername: twitter
    }
  }
`

const SEO = ({
  title,
  description,
  image,
  pathname,
  canonical,
  lang,
  meta,
  link,
  robots,
}) => (
  <StaticQuery
    query={query}
    render={({
      contentfulBrand: {
        defaultTitle,
        defaultDescription,
        siteUrl,
        twitterUsername,
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${image || defaultImage}`,
        url: `${siteUrl}/${
          pathname === undefined || pathname === null || pathname === "home"
            ? ""
            : pathname + "/"
        }`,
        canonical: `${siteUrl}/${
          canonical === "home" || pathname === "home"
            ? ""
            : canonical === undefined || canonical === null
            ? pathname + "/"
            : canonical + "/"
        }`,
        robots: robots || `index, follow`,
      }

      return (
        <Helmet
          htmlAttributes={{ lang }}
          title={seo.title}
          titleTemplate={`%s | ${defaultTitle}`}
          link={[{ rel: `canonical`, href: seo.canonical }].concat(link)}
          meta={[
            {
              name: `robots`,
              content: seo.robots,
            },
            {
              name: `description`,
              content: seo.description,
            },
            {
              property: `og:url`,
              content: seo.url,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: seo.description,
            },
            {
              name: `twitter:creator`,
              content: twitterUsername,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: seo.description,
            },
            {
              name: `twitter:image`,
              content: seo.image,
            },
          ]
            .concat(
              seo.image
                ? [
                    {
                      property: `og:image`,
                      content: `${siteUrl}${seo.image}`,
                    },
                    {
                      property: `og:image:alt`,
                      content: `Stimpack`,
                    },
                    {
                      property: `og:image:width`,
                      content: 1200,
                    },
                    {
                      property: `og:image:height`,
                      content: 1200,
                    },
                    {
                      name: `twitter:card`,
                      content: `summary_large_image`,
                    },
                  ]
                : [
                    {
                      name: `twitter:card`,
                      content: `summary`,
                    },
                  ]
            )
            .concat(meta)}
        >
          {process.env.NODE_ENV === "development" && (
            <script
              charset="utf-8"
              type="text/javascript"
              src="//js.hsforms.net/forms/shell.js"
            ></script>
          )}
          <html lang="en" className="overflow-y-scroll" />
        </Helmet>
      )
    }}
  />
)

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  robots: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  lang: `en`,
  meta: [],
  link: [],
  robots: null,
}

export default SEO
