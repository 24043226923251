import React, { Fragment } from "react"
import { Link } from "gatsby"

const NavLinks = ({ links, navMobile }) => {
  return (
    <div
      className={`${
        navMobile
          ? "md:hidden p-6 flex flex-col bg-white"
          : "relative hidden md:flex md:flex-row md:h-full md:text-white md:justify-center md:items-center"
      }`}
    >
      {links.map(({ id, linkSlug, linkText, childLinks }) => (
        <Fragment key={id}>
          {!childLinks ? (
            <>
              {linkSlug === "reviews" ? (
                <div
                  className={`select-none uppercase font-bold ${
                    navMobile
                      ? "py-3 pl-1 text-xl border-b hover:bg-brand-light-shade text-brand-dark text-opacity-25"
                      : "mx-3 text-xl opacity-50"
                  }`}
                >
                  {linkText}
                </div>
              ) : (
                <Link
                  to={`/${
                    linkSlug !== null && linkSlug !== undefined
                      ? linkSlug + "/"
                      : ""
                  }`}
                  className={`hover:text-brand uppercase font-bold ${
                    navMobile
                      ? "py-3 pl-1 text-xl border-b hover:bg-brand-light-shade"
                      : "mx-3 text-xl"
                  }`}
                  activeClassName="text-brand"
                >
                  {linkText}
                </Link>
              )}
            </>
          ) : (
            <div className={`${navMobile ? "flex flex-col" : ""}`}>
              <Link
                to={`/${
                  linkSlug !== null && linkSlug !== undefined ? linkSlug : ""
                }/`}
                className={`hover:text-brand ${
                  navMobile
                    ? "w-full py-1 pl-1 border-b hover:bg-brand-light-shade"
                    : ""
                }`}
                activeClassName="text-brand"
              >
                {linkText}
              </Link>
              <div className={`${navMobile ? "flex flex-col" : "hidden"}`}>
                {childLinks &&
                  childLinks.map(({ id, linkSlug, linkText }) => (
                    <Link
                      key={id}
                      to={`/${
                        linkSlug !== null && linkSlug !== undefined
                          ? linkSlug
                          : ""
                      }/`}
                      className={`${
                        navMobile
                          ? "py-1 pl-4 border-b hover:text-brand hover:bg-brand-light-shade"
                          : ""
                      }`}
                      activeClassName="text-brand"
                    >
                      {linkText}
                    </Link>
                  ))}
              </div>
            </div>
          )}
        </Fragment>
      ))}
      {/* <Link
        className={`mr-auto uppercase bg-brand hover:bg-brand-lighter text-xl text-white py-3 px-4 rounded-sm ${
          navMobile ? "mt-3" : ""
        }`}
        to="product/stimpack-energy"
      >
        Coming Soon
      </Link> */}
    </div>
  )
}

export default NavLinks
