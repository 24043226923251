import React from "react"
import { Link } from "gatsby"

const NavLogo = ({ logo }) => {
  return (
    <Link to="/">
      <img src={logo.file.url} alt="logo" width="115.35483870968" height="48" />
    </Link>
  )
}

export default NavLogo
